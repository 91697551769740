import React, { useEffect, useRef, useState } from "react";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import analyticService from "../../services/analytic.service";
import { Paginator, Toast } from "primereact";
import { AnalyticsTable } from "./AnalyticsTable";
import { AnalyticsPanelContain } from "./AnalyticsPanelContain";
import { Panel } from "../_slidePanel/Panel";
import userService from "../../services/user.service";
import { ConfirmationDialog } from "./ConfirmationDialog";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  name: string;
  description: string;
  userIds: any;
}

const AnalyticsAbm = ({ resetView }) => {
  const { vnConfiguracion: viewName, vfAbmAnalytics: viewFunction } = viewTypes;
  const showViewFunc = ShowView(viewFunction, viewName);
  const userId = localStorage.getItem("user");
  const userName = localStorage.getItem("userName");

  const analyticEmpty = {
    id: "",
    name: "",
    frame: "",
    description: "",
    userIds: "",
  };

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [analytic, setAnalytic] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams: params = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    name: "",
    description: "",
    userIds: "",
  };

  const [lazyParams, setLazyParams] = useState<params | null>(initParams);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    obtenerUsers();
    loadLazyData();
  }, [lazyParams]);

  const obtenerUsers = () => {
    userService
      .GetParents(0, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setUsers(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const toast = useRef(null);
  let loadLazyTimeout = null;

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      name: lazyParams.name,
      description: lazyParams.description,
      userIds: lazyParams.userIds === "" ? [] : lazyParams.userIds,
      size: lazyParams.rows,
      page: lazyParams.page,
      field: lazyParams.sortField,
      direction: lazyParams.direction ? direction : null,
    };

    loadLazyTimeout = analyticService
      .ObtenerAnalytics(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          const parsedData = response.data.reports;

          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const saveAnalytic = () => {
    const analyticObj: any = {
      id: analytic.id,
      name: analytic.name,
      description: analytic.description,
      frame: analytic.frame,
      userIds: analytic.userIds || [],
    };

    if (analytic.id != "") {
      analyticService
        .UpdateAnalytic(
          analytic.id,
          analyticObj,
          userName,
          viewName,
          viewFunction
        )
        .then((response: any) => {
          try {
            setAnalytic(analyticObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Reporte modificado",
              life: 3000,
            });
            setAnalytic(analyticEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      analyticService
        .CrearAnalytic(analyticObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setAnalytic(analyticObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Intención guardada",
              life: 3000,
            });
            setAnalytic(analyticEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setAnalytic(analyticEmpty);
  };

  const onDataChange = (name, value) => {
    let _analytic = { ...analytic };
    _analytic[`${name}`] = value;
    setAnalytic(_analytic);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const openNew = () => {
    setAnalytic(analyticEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 600);
  };

  const editAnalytic = (analytic) => {
    setAnalytic({ ...analytic });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const confirmDeleteAnalytic = (analytic) => {
    setAnalytic({ ...analytic });
    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const deleteAnalytic = () => {
    const _id = analytic.id;
    analyticService
      .borrarReporte(_id, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          hideDeleteDialog();

          toast.current.show({
            severity: "success",
            summary: "Borrar reporte",
            detail: "Eliminado correctamente",
            life: 3000,
          });
          setAnalytic(analyticEmpty);
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el borrado",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    name: null,
    frame: null,
    description: null,
    userIds: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <AnalyticsTable
            customers={customers}
            initParams={initParams}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            editAnalytic={editAnalytic}
            confirmDeleteAnalytic={confirmDeleteAnalytic}
            openNew={openNew}
            resetView={resetView}
            users={users}
          />
          <ConfirmationDialog
            analytic={analytic}
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            deleteAnalytic={deleteAnalytic}
          />
          {analytic && users && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <AnalyticsPanelContain
                analytic={analytic}
                saveAnalytic={saveAnalytic}
                onDataChange={onDataChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                users={users}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
};

export default AnalyticsAbm;
