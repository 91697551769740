import React, { useEffect, useRef, useState } from "react";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { Paginator, Toast } from "primereact";
import { GroupPanelContain } from "./GroupPanelContain";
import { Panel } from "../_slidePanel/Panel";
import userService from "../../services/user.service";
import { ConfirmationDialog } from "./ConfirmationDialog";
import groupService from "../../services/group.service";
import { GroupTable } from "./GroupTable";

interface params {
  pageSize: number;
  pageNumber: number;
  field: any;
  direction: any;
  name: string;
}

const GrpupAbm = ({ resetView }) => {
  const { vnConfiguracion: viewName, vfAbmGruposUsuarios: viewFunction } =
    viewTypes;
  const showViewFunc = ShowView(viewFunction, viewName);
  const userName = localStorage.getItem("userName");

  const groupEmpty = {
    id: "",
    name: "",
  };

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams: params = {
    pageSize: 10,
    pageNumber: 1,
    field: null,
    direction: null,
    name: "",
  };

  const [lazyParams, setLazyParams] = useState<params | null>(initParams);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    obtenerUsers();
    loadLazyData();
  }, [lazyParams]);

  const obtenerUsers = () => {
    userService
      .GetParents(0, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setUsers(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const toast = useRef(null);
  let loadLazyTimeout = null;

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      name: lazyParams.name,
      pageSize: lazyParams.pageSize,
      pageNumber: lazyParams.pageNumber,
      field: lazyParams.field,
      direction: lazyParams.direction ? direction : null,
    };

    loadLazyTimeout = groupService
      .ObtenerGrupos(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          const parsedData = response.data.grupos;

          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const saveGroup = () => {
    const groupObj: any = {
      id: group.id,
      name: group.name,
    };

    if (group.id != "") {
      groupService
        .UpdateGroup(group.id, groupObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setGroup(groupObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Grupo modificado",
              life: 3000,
            });
            setGroup(groupEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      groupService
        .CrearGroup(groupObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setGroup(groupObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Intención guardada",
              life: 3000,
            });
            setGroup(groupEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setGroup(groupEmpty);
  };

  const onDataChange = (name, value) => {
    let _group = { ...group };
    _group[`${name}`] = value;
    setGroup(_group);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        pageNumber: event.page + 1,
        pageSize: event.rows,
      };
    });
  };

  const openNew = () => {
    setGroup(groupEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const editGroup = (group) => {
    setGroup({ ...group });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const confirmDeleteGroup = (group) => {
    setGroup({ ...group });
    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const deleteGroup = () => {
    const _id = group.id;
    groupService
      .BorrarGroup(_id, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          hideDeleteDialog();

          toast.current.show({
            severity: "success",
            summary: "Borrar grupo",
            detail: "Eliminado correctamente",
            life: 3000,
          });
          setGroup(groupEmpty);
          loadLazyData();
        } catch (err) {
          hideDeleteDialog();
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el borrado",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.pageSize}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    name: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <GroupTable
            customers={customers}
            initParams={initParams}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            editGroup={editGroup}
            confirmDeleteGroup={confirmDeleteGroup}
            openNew={openNew}
            resetView={resetView}
          />
          <ConfirmationDialog
            group={group}
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            deleteGroup={deleteGroup}
          />
          {group && users && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <GroupPanelContain
                group={group}
                saveGroup={saveGroup}
                onDataChange={onDataChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
};

export default GrpupAbm;
