import http from "../common/components/http-common";
import { IAsteriskType } from "../types/asterisk.type";

class AsteriskService {
  ObtenerAsterisks(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        serverName: data.serverName,
        ip: data.ip,
        active: data.active,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        direction: data.direction,
        field: data.field,
      },
    };

    return http.get<any>("/v1/asterisk/", config);
  }

  CrearAsterisk(
    data: IAsteriskType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IAsteriskType>("/v1/asterisk/", data, config);
  }

  UpdateAsterisk(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IAsteriskType>("/v1/asterisk/" + id, data, config);
  }
}

export default new AsteriskService();
