import React from "react";
import moment from "moment";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const AnalyticsTable = ({
  customers,
  initParams,
  lazyParams,
  setLazyParams,
  loading,
  footerPage,
  editAnalytic,
  confirmDeleteAnalytic,
  openNew,
  resetView,
  users,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const handleOptionsUsers = (data) => {
    const op = [];

    data.forEach((user) => {
      const obj = {
        name: user.name,
        value: user.id,
      };
      op.push(obj);
    });
    return op;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editAnalytic(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => confirmDeleteAnalytic(rowData)}
        />
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    const fecha = moment(rowData.creationDateTime);
    return fecha.format("DD/MM/YYYY HH:mm");
  };

  const usersBodyTemplate = (rowData) => {
    return (
      <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
        {rowData.userIds.map((u) => (
          <span key={u} className="user-list-item">
            {((users && users.find((usuario) => usuario.id === u)) || {}).name}
          </span>
        ))}
      </ul>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Analytics</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {users && (
        <FilterPanel
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          optionsUsers={handleOptionsUsers(users)}
        />
      )}

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          alignHeader="left"
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
        />
        <Column
          alignHeader="left"
          field="description"
          showFilterMenu={false}
          sortable
          header="Descripción"
        />
        <Column
          field="userIds"
          showFilterMenu={false}
          header="Usuarios"
          body={usersBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyClassName={"centerRowData"}
          field="creationDateTime"
          sortable
          header="Fecha"
          alignHeader="center"
          body={dateBodyTemplate}
        />

        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
