import React, { useState, useEffect } from "react";
import { Tree } from "primereact";

export const UserPermissions = ({
  permisos,
  user,
  setPermisosUsuario,
  permisosDisabled,
}) => {
  const [permisosTree, setPermisosTree] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState({});

  useEffect(() => {
    formatTree(permisos, user.permisos || []);
  }, [user.permisos]);

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
      for (let child of node.children) {
        expandNode(child, expandedKeys);
      }
    }
  };

  const expandAll = (tree) => {
    let _expandedKeys = {};
    for (let node of tree) {
      expandNode(node, _expandedKeys);
    }
    setExpandedKeys(_expandedKeys);
  };

  const onInputChange = (e) => {
    setSelectedKeys(e.value);
    let perm = [];
    const keys = Object.keys(e.value);
    keys.forEach((k) => {
      permisosTree.forEach((n) => {
        n.children.forEach((c) => {
          if (c.key === k) {
            perm.push(c.data);
          }
        });
      });
      setPermisosUsuario(perm);
    });
  };

  const formatTree = (permisos, permisosUser) => {
    interface IViewName {
      key: string;
      label: string;
      children: [];
    }
    interface IChild {
      key: string;
      label: string;
      data: string;
    }

    let viewName = [];
    let permi = [];

    permisos.forEach((n) => {
      viewName.push(n.view_name);
    });

    viewName = viewName.filter((item, index) => {
      return viewName.indexOf(item) === index;
    });

    for (let i = 0; i < viewName.length; i++) {
      const obj: IViewName = {
        key: (i + 1).toString(),
        label: viewName[i],
        children: [],
      };
      permi.push(obj);
    }

    permi.forEach((p) => {
      for (let i = 0; i < permisos.length; i++) {
        if (p.label === permisos[i].view_name) {
          const obj: IChild = {
            key: p.key + "-" + (i + 1).toString(),
            label: permisos[i].view_function,
            data: permisos[i].id,
          };
          p.children.push(obj);
        }
      }
    });

    /* preseleccionados */
    let checkList = [];
    let check = {};

    permi.forEach((p) => {
      p.children.forEach((c) => {
        permisosUser.forEach((pu) => {
          if (c.data === pu.id) {
            const entries = new Map([[`${c.key}`, { checked: true }]]);
            let obj = Object.fromEntries(entries);
            checkList.push(obj);
          }
        });
      });
    });

    /* parents */
    permi.forEach((p) => {
      p.actual = 0;

      permisosUser.forEach((pu) => {
        p.children.forEach((c) => {
          if (pu.id === c.data) {
            p.actual = p.actual + 1;
          }
        });
      });

      if (p.actual === p.children.length) {
        const entries = new Map([
          [`${p.key}`, { checked: true, partialChecked: false }],
        ]);

        let obj = Object.fromEntries(entries);

        checkList.push(obj);
      } else if (p.actual > 0) {
        const entries = new Map([
          [`${p.key}`, { checked: false, partialChecked: true }],
        ]);
        let obj = Object.fromEntries(entries);
        checkList.push(obj);
      }
    });

    checkList.forEach((c) => {
      Object.assign(check, c);
    });
    setSelectedKeys(check);

    setPermisosTree(permi);
    expandAll(permi);
  };

  return (
    <div className="flex flex-column">
      {permisosTree && (
        <Tree
          style={{ border: "0" }}
          value={permisosTree}
          selectionMode="checkbox"
          selectionKeys={selectedKeys}
          onSelectionChange={(e) => onInputChange(e)}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          disabled={permisosDisabled}
          filter
        />
      )}
    </div>
  );
};
