import React from "react";

export const Panel = ({ isOpen, closePanel, children }) => {
  const panelContentWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { closePanel });
  });

  return (
    <div className={`sliding-panel ${isOpen ? "open" : ""}`}>
      <div className="panel-content">{panelContentWithProps}</div>
    </div>
  );
};
