import React, { useEffect } from "react";
import {
  Button,
  Dropdown,
  InputSwitch,
  InputText,
  Toolbar,
  classNames,
} from "primereact";
import { ClientSelects } from "./ClientSelects";

export const EntidadesPanelContain = ({
  client,
  saveClient,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  rubros,
  selectedRubroTemplate,
  itemRubroTemplate,
  onClientChange,
  onTTSChange,
  onIdiomaChange,
  onValidationChange,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [client]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!client.nombre) {
      newErrors.nombre = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.nombre = null;
    }

    if (!client.notificationPropertiesMilliseconds) {
      newErrors.notificationPropertiesMilliseconds =
        "Ingresa las actualizaciones";
      valid = false;
    } else {
      newErrors.notificationPropertiesMilliseconds = null;
    }

    if (!client.credits) {
      newErrors.credits = "Ingresa los créditos";
      valid = false;
    } else {
      newErrors.credits = null;
    }

    if (!client.keyReportQueue) {
      newErrors.keyReportQueue = "Ingresa los key reportes";
      valid = false;
    } else {
      newErrors.keyReportQueue = null;
    }

    if (!client.reintentos) {
      newErrors.reintentos = "Ingresa los reintentos";
      valid = false;
    } else {
      newErrors.reintentos = null;
    }

    if (!client.rubro) {
      newErrors.rubro = "Elige el rubro";
      valid = false;
    } else {
      newErrors.rubro = null;
    }

    if (!client.telephoneValidation) {
      newErrors.telephoneValidation = "Elige el validador";
      valid = false;
    } else {
      newErrors.telephoneValidation = null;
    }

    if (!client.langTranscript) {
      newErrors.langTranscript = "Elige el idioma";
      valid = false;
    } else {
      newErrors.langTranscript = null;
    }

    if (!client.keyTtsQueue) {
      newErrors.keyTtsQueue = "Ingresa el keyTTS";
      valid = false;
    } else {
      newErrors.keyTtsQueue = null;
    }

    if (!client.configuracionTTSVoiceName) {
      newErrors.configuracionTTSVoiceName = "Elige el TTS";
      valid = false;
    } else {
      newErrors.configuracionTTSVoiceName = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle cliente</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveClient();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            value={client.nombre}
            onChange={(e) => onInputChange(e, "nombre")}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.nombre,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.nombre && (
            <small className="p-error block">{errors.nombre}</small>
          )}
        </div>

        <div className="sep"></div>

        <div className="row">
          <div className="column">
            <div className="field switch">
              <InputSwitch
                checked={client.estado}
                onChange={(e) => onInputChange(e, "estado")}
              />
              <label htmlFor="estado">Estado</label>
            </div>

            <div className="field switch">
              <InputSwitch
                checked={client.configuracionGrabarLlamadas}
                onChange={(e) =>
                  onInputChange(e, "configuracionGrabarLlamadas")
                }
              />
              <label htmlFor="configuracionGrabarLlamadas">
                Grabar llamadas
              </label>
            </div>

            <div className="field switch">
              <InputSwitch
                checked={client.notificationProperties}
                onChange={(e) => onInputChange(e, "notificationProperties")}
              />
              <label htmlFor="notificationProperties">
                Activar notificaciones
              </label>
            </div>
          </div>
          <div className="column">
            <div className="field switch">
              <InputSwitch
                checked={client.configuracionAMD}
                onChange={(e) => onInputChange(e, "configuracionAMD")}
              />
              <label htmlFor="configuracionAMD">Configuración de AMD</label>
            </div>

            <div className="field switch">
              <InputSwitch
                checked={client.configuracionGrabarTransferencias}
                onChange={(e) =>
                  onInputChange(e, "configuracionGrabarTransferencias")
                }
              />
              <label htmlFor="configuracionGrabarTransferencias">
                Grabar transferencias
              </label>
            </div>

            <div className="field switch">
              <InputSwitch
                checked={client.configuracionSincronizarTTs}
                onChange={(e) =>
                  onInputChange(e, "configuracionSincronizarTTs")
                }
              />
              <label htmlFor="configuracionSincronizarTTs">
                Sincronizar TTS
              </label>
            </div>
          </div>
        </div>

        <div className="sep"></div>

        <div className="row">
          <div className="column">
            <div className="field">
              <label htmlFor="notificationPropertiesMilliseconds">
                Actualiz. de notific. (ms)
              </label>
              <InputText
                value={client.notificationPropertiesMilliseconds}
                required
                autoComplete="off"
                placeholder="Ingresa las actualizaciones"
                onChange={(e) =>
                  onInputChange(e, "notificationPropertiesMilliseconds")
                }
                className={classNames({
                  "p-invalid":
                    submitted && errors.notificationPropertiesMilliseconds,
                })}
              />
              {errors.notificationPropertiesMilliseconds && (
                <small className="p-error block">
                  {errors.notificationPropertiesMilliseconds}
                </small>
              )}
            </div>

            <div className="field">
              <label htmlFor="credits">Créditos</label>
              <InputText
                value={client.credits}
                required
                autoComplete="off"
                placeholder="Ingresa los créditos"
                onChange={(e) => onInputChange(e, "credits")}
                className={classNames({
                  "p-invalid": submitted && errors.credits,
                })}
              />
              {errors.credits && (
                <small className="p-error block">{errors.credits}</small>
              )}
            </div>

            <div className="sep"></div>
          </div>

          <div className="column">
            <div className="field">
              <label htmlFor="keyReportQueue">Key reportes</label>
              <InputText
                value={client.keyReportQueue}
                required
                autoComplete="off"
                placeholder="Ingresa los key reportes"
                onChange={(e) => onInputChange(e, "keyReportQueue")}
                className={classNames({
                  "p-invalid": submitted && errors.keyReportQueue,
                })}
              />
              {errors.keyReportQueue && (
                <small className="p-error block">{errors.keyReportQueue}</small>
              )}
            </div>

            <div className="field">
              <label htmlFor="reintentos">Reintentos</label>
              <InputText
                value={client.reintentos}
                required
                autoComplete="off"
                placeholder="Ingresa los reintentos"
                onChange={(e) => onInputChange(e, "reintentos")}
                className={classNames({
                  "p-invalid": submitted && errors.reintentos,
                })}
              />
              {errors.reintentos && (
                <small className="p-error block">{errors.reintentos}</small>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="rubros">Rubros</label>
          <Dropdown
            value={rubros && rubros.find((r) => r.name === client.rubro)}
            onChange={(e) => onClientChange(e.value)}
            options={rubros}
            valueTemplate={selectedRubroTemplate}
            itemTemplate={itemRubroTemplate}
            optionLabel="name"
            placeholder="Elige el rubro"
            className={classNames({
              "p-invalid": submitted && errors.rubro,
            })}
          />
          {errors.rubro && (
            <small className="p-error block">{errors.rubro}</small>
          )}
        </div>

        <div className="sep"></div>

        <ClientSelects
          submitted={submitted}
          client={client}
          onInputChange={onInputChange}
          onTTSChange={onTTSChange}
          onIdiomaChange={onIdiomaChange}
          onValidationChange={onValidationChange}
          errors={errors}
        />
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
