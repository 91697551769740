import React, { useState } from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { styleEstado } from "../../utils/option.util";
import { FilterPanel } from "./FilterPanel";

export const UserTable = ({
  customers,
  lazyParams,
  loading,
  footerPage,
  openNew,
  editUser,
  editPermissions,
  confirmSwitchUser,
  resetView,
  setLazyParams,
  initParams,
  clientes,
  grupos,
  optionsAsterisks,
  optionsVendors,
  showPermisos,
}) => {
  const [sortField, setSortField] = useState(null);

  const onSort = (event) => {
    setLazyParams((previousState) => {
      let field = null;
      switch (event.sortField) {
        case "nombre":
          field = "name";
          break;
        case "cliente":
          field = "client";
          break;
        case "grupoName":
          field = "grupo";
          break;
        case "estado":
          field = "active";
          break;
        default:
          field = event.sortField;
          break;
      }

      setSortField(event.sortField);
      return {
        ...previousState,
        sortField: field,
        direction: event.sortOrder,
      };
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Usuarios</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-user-edit"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editUser(rowData)}
        />
        {showPermisos && (
          <Button
            icon="pi pi-key"
            className="p-button-rounded p-button-success mr-2"
            onClick={() => editPermissions(rowData)}
          />
        )}
        <Button
          icon={rowData.estado ? "pi pi-unlock" : "pi pi-lock"}
          className={
            rowData.estado
              ? "p-button p-button-rounded p-button-success"
              : "p-button p-button-rounded p-button-danger"
          }
          onClick={() => confirmSwitchUser(rowData)}
        />
      </div>
    );
  };

  const clienteBodyTemplate = (rowData) => {
    return <span>{rowData.cliente}</span>;
  };

  const grupoBodyTemplate = (rowData) => {
    return <span>{rowData.grupoName}</span>;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(rowData.estado) +
          " text-status"
        }
      >
        {rowData.estado ? "Activo" : "Inactivo"}
      </span>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {clientes && grupos && optionsAsterisks && optionsVendors && (
        <FilterPanel
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          clientes={clientes}
          grupos={grupos}
          optionsAsterisks={optionsAsterisks}
          optionsVendors={optionsVendors}
        />
      )}

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="nombre"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="email"
          showFilterMenu={false}
          sortable
          header="Email"
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="cliente"
          sortable
          showFilterMenu={false}
          header="Cliente"
          body={clienteBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="grupoName"
          sortable
          showFilterMenu={false}
          header="Grupo"
          body={grupoBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="estado"
          sortable
          showFilterMenu={false}
          header="Estado"
          body={statusBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
