import http from "../common/components/http-common";
import { IVendorFilterType, IVendorType } from "../types/vendor.type";
var qs = require("qs");

class VendorService {
  ObtenerProveedores(
    data: IVendorFilterType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    const parameter = {
      name: data.name,
      queueName: data.queueName,
      context: data.context,
      asterisks: data.asterisks,
      direction: data.direction,
      field: data.sortField,
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: parameter,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "brackets" });
      },
    };

    return http.get<IVendorFilterType>("/v1/vendor/", config);
  }

  CrearVendor(
    data: IVendorType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IVendorType>("/v1/vendor/", data, config);
  }

  UpdateVendor(
    id,
    data: IVendorType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IVendorType>("/v1/vendor/" + id, data, config);
  }

  GetVendorAll(userName, viewName, viewFunction) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/vendor/all/", config);
  }
}

export default new VendorService();
