import http from "../common/components/http-common";

class RubroDataService {
  obtenerRubros(userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/rubros/", config);
  }
}

export default new RubroDataService();
