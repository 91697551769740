import React, { useState } from "react";
import { styleEstado } from "../../utils/option.util";
import { Dropdown, InputText, MultiSelect } from "primereact";
import { FilterWrapper } from "../_filterPanel/FilterWrapper";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  grupos,
  clientes,
  optionsAsterisks,
  optionsVendors,
}) => {
  const initFilters = {
    nombre: "",
    email: "",
    cliente: "",
    grupo: "",
    estado: "",
    vendors: "",
    asterisks: "",
  };

  const [filters, setFilters] = useState(initFilters);

  const optionsStatus = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Inactivo",
      value: false,
    },
  ];

  const itemTemplate = (data) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(data.label === "Activo") +
          " text-status"
        }
      >
        {data.label}
      </span>
    );
  };

  const selectedTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge status-" +
            styleEstado(option.label === "Activo") +
            " text-status"
          }
        >
          {option.label}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const filtros = [
    {
      title: "Nombre",
      filtro: "nombre",
      input: (
        <InputText
          className="filter-text"
          value={filters.nombre}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Email",
      filtro: "email",
      input: (
        <InputText
          className="filter-text"
          value={filters.email}
          placeholder="Ingresa el email"
        />
      ),
    },
    {
      title: "Cliente",
      filtro: "cliente",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.cliente}
            options={clientes}
            onChange={(e) => handleChange("cliente", e.value)}
            placeholder="Elige el cliente"
            optionLabel="name"
          />
        </div>
      ),
    },
    {
      title: "Grupo",
      filtro: "grupo",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.grupo}
            options={grupos}
            onChange={(e) => handleChange("grupo", e.value)}
            placeholder="Elige el grupo"
            optionLabel="name"
          />
        </div>
      ),
    },
    {
      title: "Estado",
      filtro: "estado",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.estado}
            options={optionsStatus}
            onChange={(e) => handleChange("estado", e.value)}
            placeholder="Elige un estado"
            valueTemplate={selectedTemplate}
            itemTemplate={itemTemplate}
          />
        </div>
      ),
    },
    {
      title: "Vendors",
      filtro: "vendors",
      input: (
        <div className="select-container">
          <MultiSelect
            value={lazyParams.vendors}
            options={optionsVendors}
            onChange={(e) => handleChange("vendors", e.value)}
            optionLabel="name"
            filterBy="name"
            placeholder="Elige los vendors"
          />
        </div>
      ),
    },
    {
      title: "Asterisks",
      filtro: "asterisks",
      input: (
        <div className="select-container">
          <MultiSelect
            value={lazyParams.asterisks}
            options={optionsAsterisks}
            onChange={(e) => handleChange("asterisks", e.value)}
            optionLabel="name"
            filterBy="name"
            placeholder="Elige los asterisks"
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
