import AnalyticsAbm from "./analytics/AnalyticsAbm";
import ClientAbm from "./client/ClientAbm";
import GrpupAbm from "./group/GroupAbm";
import UserAbm from "./user/UserAbm";

export default function ViewsConfig() {
  const resetView = () => {
    window.location.href = "/configuration";
  };
  const optionView = sessionStorage.getItem("optionView");

  const selectView = () => {
    switch (optionView) {
      case "Clientes":
        return <ClientAbm resetView={resetView} />;
      case "Usuarios":
        return <UserAbm resetView={resetView} />;
      case "Grupos de usuarios":
        return <GrpupAbm resetView={resetView} />;
      case "Analytics":
        return <AnalyticsAbm resetView={resetView} />;
      default:
        return <NullView />;
    }
  };

  return <>{selectView()}</>;
}

const NullView = () => {
  return <></>;
};
