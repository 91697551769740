import React, { useEffect } from "react";
import {
  Button,
  InputText,
  InputTextarea,
  MultiSelect,
  Toolbar,
  classNames,
} from "primereact";

export const AnalyticsPanelContain = ({
  analytic,
  saveAnalytic,
  onDataChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  users,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [analytic]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!analytic.name.trim()) {
      newErrors.name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.name = null;
    }

    if (!analytic.description.trim() || analytic.description.length > 400) {
      newErrors.description = "Ingresa una descripción, máximo 400 caracteres";
      valid = false;
    } else {
      newErrors.description = null;
    }

    if (!validarUrlPBI(analytic.frame)) {
      newErrors.frame = "Ingresa una url válida";
      valid = false;
    } else {
      newErrors.frame = null;
    }

    if (!analytic.userIds || analytic.userIds.length === 0) {
      newErrors.userIds = "Elige al menos un usuario";
      valid = false;
    } else {
      newErrors.userIds = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const validarUrlPBI = (campo) => {
    var regex = /^https:\/\/app\.powerbi\.com\/view\?r=[^'"]+$/;
    return regex.test(campo);
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle analytics</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveAnalytic();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const handleUsers = (value) => {
    const _ids = value.map((item) => item.id);
    onDataChange("userIds", _ids);
  };

  const filtrarUsers = (users, ids) => {
    if (!ids) return null;
    return users.filter((user) => ids.includes(user.id));
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="name"
            value={analytic.name}
            onChange={(e) => onDataChange("name", e.target.value)}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.name,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.name && (
            <small className="p-error block">{errors.name}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="userIds">Usuarios</label>
          <MultiSelect
            value={filtrarUsers(users, analytic.userIds)}
            options={users}
            onChange={(e) => handleUsers(e.value)}
            optionLabel="name"
            filterBy="name"
            placeholder="Selecciona los usuarios"
            className={classNames({
              "p-invalid": submitted && errors.userIds,
            })}
          />
          {errors.userIds && (
            <small className="p-error block">{errors.userIds}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="desciption">Descripción</label>

          <InputTextarea
            value={analytic.description}
            onChange={(e) => onDataChange("description", e.target.value)}
            rows={5}
            cols={30}
            className={classNames({
              "p-invalid": submitted && errors.description,
            })}
          />
          {errors.description && (
            <small className="p-error block">{errors.description}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="frame">URL PowerBI</label>

          <InputTextarea
            value={analytic.frame}
            onChange={(e) => onDataChange("frame", e.target.value)}
            rows={5}
            cols={30}
            className={classNames({
              "p-invalid": submitted && errors.frame,
            })}
          />
          {errors.frame && (
            <small className="p-error block">{errors.frame}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
