import React, { useState } from "react";
import { Button, Column, DataTable, Dropdown, Toolbar } from "primereact";
import { styleEstado, styleRubro } from "../../utils/option.util";
import { FilterPanel } from "./FilterPanel";

export const ClientTable = ({
  customers,
  lazyParams,
  setLazyParams,
  initParams,
  loading,
  footerPage,
  openNew,
  editClient,
  rubros,
  confirmSwitchClient,
  resetView,
  selectedRubroTemplate,
  itemRubroTemplate,
}) => {
  const [sortField, setSortField] = useState(null);

  const onSort = (event) => {
    let field = null;
    switch (event.sortField) {
      case "nombre":
        field = "name";
        break;
      case "estado":
        field = "active";
        break;
      default:
        field = event.sortField;
        break;
    }

    setSortField(event.sortField);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: field,
        direction: event.sortOrder,
      };
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Clientes</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const rubroBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" + styleRubro(rowData.rubro) + " text-status"
        }
      >
        {rowData.rubro}
      </span>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(rowData.estado) +
          " text-status"
        }
      >
        {rowData.estado ? "Activo" : "Inactivo"}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editClient(rowData)}
        />

        <Button
          icon={rowData.estado ? "pi pi-unlock" : "pi pi-lock"}
          className={
            rowData.estado
              ? "p-button-rounded p-button-success mt-2"
              : "p-button-rounded p-button-danger mt-2"
          }
          onClick={() => confirmSwitchClient(rowData)}
        />
      </div>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {rubros && (
        <FilterPanel
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          rubros={rubros}
          selectedRubroTemplate={selectedRubroTemplate}
          itemRubroTemplate={itemRubroTemplate}
        />
      )}

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="nombre"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="rubro"
          sortable
          showFilterMenu={false}
          header="Rubro"
          body={rubroBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          field="estado"
          sortable
          showFilterMenu={false}
          header="Estado"
          body={statusBodyTemplate}
          alignHeader="center"
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
