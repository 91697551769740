import { getAllPermisos } from "../services/permissions.service";

export const ObtenerPermisosUser = () => {
  const userName = localStorage.getItem("userName");
  getAllPermisos(userName).then((response: any) => {
    try {
      let parsedData = response.data;
      localStorage.setItem("permisos", JSON.stringify(parsedData));
    } catch (err) {
      const error = err as { message?: string };
    }
  });
};
