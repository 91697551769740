import http from "../common/components/http-common";
import { IUserTypeRequest, IUserTypeRequestCreated } from "../types/user.type";
var qs = require("qs");

class UserService {
  ObtenerUsuarios(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    const params = {
      name: data.name,
      email: data.email,
      active: data.active,
      clientId: data.clientId,
      direction: data.direction,
      field: data.field,
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
      grupoId: data.grupoId,
      vendors: data.vendors,
      asterisks: data.asterisks,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "brackets" });
      },
    };

    return http.get<any>("/v1/users/", config);
  }

  CrearUsuario(
    data: IUserTypeRequestCreated,
    userName,
    viewName,
    viewFunction
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IUserTypeRequest>("/v1/users/", data, config);
  }

  ModificarUsuario(
    id,
    data: IUserTypeRequestCreated,
    userName,
    viewName,
    viewFunction
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.put<IUserTypeRequest>("/v1/users/" + id, data, config);
  }

  updatePartial(id, data, userName, viewName, viewFunction) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.patch<any>("/v1/users/" + id, data, config);
  }

  GetParents(userId, userName, viewName, viewFunction) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.get<any>("/v1/users/parents/" + userId, config);
  }
}

export default new UserService();
