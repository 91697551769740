import React, { useEffect, useState } from "react";
import { viewTypes } from "../../common/types/views.type";
import vendorService from "../../services/vendor.service";
import { Dropdown, Tree } from "primereact";

export const UserVendorAsterisks = ({
  user,
  retornoVendorAsterisk,
  rendKey,
}) => {
  useEffect(() => {
    getVendors();
  }, [user.id, rendKey]);

  const [vendors, setVendors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vaDefault, setVaDefault] = useState(null);
  const [vaTree, setVaTree] = useState(null);

  const { vnConfiguracion: viewName, vfAbmUsuarios: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const getVendors = () => {
    setLoading(true);
    vendorService
      .GetVendorAll(userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setLoading(false);
          formatTree(
            response.data,
            user.vendorAsterisk,
            setVendors,
            setSelectedKeys
          );
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const [selectedKeys, setSelectedKeys] = useState(null);

  const onTreeChange = (e) => {
    setSelectedKeys(e.value);
    convertirAOptions(vendors, e.value);

    /** **/
    let va = [];
    const keys = Object.keys(e.value);
    keys.forEach((k) => {
      vendors.forEach((v) => {
        v.children.forEach((a) => {
          if (a.key === k) {
            va.push({ vendor: v.id, asterisk: a.id, esDefault: false || null });
          }
        });
      });
    });

    setVaTree(va);
    retornoVendorAsterisk(va, vaDefault);
  };

  const groupedItemTemplate = (option) => {
    return <div style={{ marginLeft: "8px" }}>{option.label}</div>;
  };

  const handleDefault = (e) => {
    setVaDefault(e.value);
    retornoVendorAsterisk(vaTree, e.value);
  };

  const [opciones, setOpciones] = useState(null);

  const convertirAOptions = (data, checked) => {
    const options = data.map((item) => {
      if (checked[item.key]) {
        const children = item.children
          ? item.children
              .filter((child) => checked[child.key])
              .map((child) => ({
                value: child.key,
                label: child.label,
              }))
          : [];

        return {
          label: item.label,
          value: item.key,
          items: children,
        };
      } else {
        return null;
      }
    });

    const _options = options.filter((item) => item !== null);
    setOpciones(_options);

    const obtenerDefault = (va) => {
      const def = va.find((item) => item.esDefault);
      return def ? [def.vendor, def.asterisk].join("-") : null;
    };

    setVaDefault(user && obtenerDefault(user.vendorAsterisk || []));
  };

  const formatTree = (data, vendorAsterisk, setVendors, setSelectedKeys) => {
    vendorAsterisk = vendorAsterisk ? vendorAsterisk : [];
    let _vendors = [];
    data.forEach((v) => {
      const obj = {
        key: v.id,
        id: v.id,
        label: v.name,
        children: [],
      };

      v.asterisks.forEach((a) => {
        const child = {
          key: v.id + "-" + a.id,
          id: a.id,
          label: a.serverName,
        };
        obj.children.push(child);
      });
      _vendors.push(obj);
    });

    /* preseleccionados */
    let checkList = [];
    let check = {};

    _vendors.forEach((v) => {
      v.children.forEach((a) => {
        vendorAsterisk.forEach((va) => {
          if (v.id === va.vendor && a.id === va.asterisk) {
            const entries = new Map([[`${a.key}`, { checked: true }]]);
            let obj = Object.fromEntries(entries);

            checkList.push(obj);
          }
        });
      });
    });

    /* parents */
    _vendors.forEach((v) => {
      v.actual = 0;

      v.children.forEach((a) => {
        vendorAsterisk.forEach((va) => {
          if (va.asterisk === a.id && va.vendor === v.id) {
            v.actual = v.actual + 1;
          }
        });
      });

      if (v.actual === v.children.length && v.actual !== 0) {
        const entries = new Map([
          [`${v.key}`, { checked: true, partialChecked: false }],
        ]);

        let obj = Object.fromEntries(entries);
        checkList.push(obj);
      } else if (v.actual > 0) {
        const entries = new Map([
          [`${v.key}`, { checked: false, partialChecked: true }],
        ]);
        let obj = Object.fromEntries(entries);
        checkList.push(obj);
      }
    });

    /** pasar a objeto **/
    checkList.forEach((c) => {
      Object.assign(check, c);
    });
    setSelectedKeys(check);
    setVendors(_vendors);

    convertirAOptions(_vendors, check);
  };

  return (
    <>
      <label htmlFor="vendors">Proveedores / Asterisks</label>

      {!loading ? (
        <>
          <VATree
            vendors={vendors}
            selectedKeys={selectedKeys}
            onTreeChange={onTreeChange}
          />

          <div
            className="field"
            style={{
              marginTop: "8px",
            }}
          >
            <label htmlFor="default">Proveedor / Asterisk por defecto</label>
            <Dropdown
              value={vaDefault}
              filter
              onChange={(e) => handleDefault(e)}
              options={opciones}
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              optionGroupTemplate={groupedItemTemplate}
              placeholder="Elige el valor por defecto"
            />
          </div>
        </>
      ) : (
        <div>
          <i className="pi pi-spin pi-spinner" style={{ marginTop: "4px" }}></i>
        </div>
      )}
    </>
  );
};

const VATree = ({ vendors, selectedKeys, onTreeChange }) => {
  return (
    <Tree
      filter
      style={{ width: "100%", height: "288px", overflowY: "auto" }}
      value={vendors}
      selectionMode="checkbox"
      selectionKeys={selectedKeys}
      onSelectionChange={(e) => onTreeChange(e)}
    />
  );
};
