import React, { useEffect, useState } from "react";
import clientService from "../../services/client.service";
import { Dropdown, InputSwitch, InputText, classNames } from "primereact";

export const ClientSelects = ({
  submitted,
  client,
  onInputChange,
  onTTSChange,
  onIdiomaChange,
  onValidationChange,
  errors,
}) => {
  useEffect(() => {
    getConfig();
  }, []);

  const [tts, setTts] = useState(null);
  const [validadores, setValidadores] = useState(null);
  const [idiomas, setIdiomas] = useState(null);

  const getConfig = () => {
    clientService.GetConfig().then((response: any) => {
      try {
        armarSelects(response.data);
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const armarSelects = (data) => {
    /* tts */
    let _tts = [];

    data.tts.forEach((t) => {
      const obj = {
        label: t.name,
        items: [],
      };

      t.voices.forEach((voice) => {
        const v = {
          label: voice,
          value: voice,
        };
        obj.items.push(v);
      });
      _tts.push(obj);
    });

    setTts(_tts);
    /* fin tts */

    /* idiomas */
    let _idiomas = [];

    data.idiomas.forEach((i) => {
      const obj = {
        name: i,
        value: i,
      };

      _idiomas.push(obj);
    });

    setIdiomas(_idiomas);
    /* fin idiomas */

    /* validadores */
    let _validadores = [];

    data.validadores.forEach((i) => {
      const obj = {
        name: i,
        value: i,
      };
      _validadores.push(obj);
    });

    setValidadores(_validadores);
    /* fin validadores */
  };

  const handleIdiomas = (e) => {
    onIdiomaChange(e);
  };

  const handleValidadores = (e) => {
    onValidationChange(e);
  };

  const handleTTs = (e) => {
    onTTSChange(getService(e), e);
  };

  const getService = (voice) => {
    let _service: string;

    tts.forEach((s) => {
      s.items.forEach((t) => {
        if (t.label === voice) {
          _service = s.label;
        }
      });
    });
    return _service;
  };

  const groupedItemTemplate = (option) => {
    return <div style={{ marginLeft: "8px" }}>{option.label}</div>;
  };

  return (
    <>
      <div className="row name-active">
        <div className="field switch">
          <InputSwitch
            checked={client.configurciontelephoneValidation}
            onChange={(e) =>
              onInputChange(e, "configurciontelephoneValidation")
            }
          />
          <label htmlFor="active">Activar validador</label>
        </div>

        {validadores && (
          <div className="field select">
            <label htmlFor="validadores">Tipo de validador</label>
            <Dropdown
              value={client.telephoneValidation}
              onChange={(e) => handleValidadores(e.value)}
              options={validadores}
              optionLabel="name"
              placeholder="Elige el validador"
              className={classNames({
                "p-invalid": submitted && errors.telephoneValidation,
              })}
            />
            {errors.telephoneValidation && (
              <small className="p-error block">
                {errors.telephoneValidation}
              </small>
            )}
          </div>
        )}
      </div>

      {idiomas && (
        <div className="field">
          <label htmlFor="idiomas">Idioma de traducción</label>
          <Dropdown
            value={client.langTranscript}
            onChange={(e) => handleIdiomas(e.value)}
            options={idiomas}
            optionLabel="name"
            placeholder="Elige el idioma"
            className={classNames({
              "p-invalid": submitted && errors.langTranscript,
            })}
          />
          {errors.langTranscript && (
            <small className="p-error block">{errors.langTranscript}</small>
          )}
        </div>
      )}

      <div className="sep"></div>

      <div className="row">
        <div className="column">
          <div className="field">
            <label htmlFor="keyTTS">Key TTS</label>
            <InputText
              value={client.keyTtsQueue}
              required
              autoComplete="off"
              placeholder="Ingresa el keyTTS"
              onChange={(e) => onInputChange(e, "keyTtsQueue")}
              className={classNames({
                "p-invalid": submitted && errors.keyTtsQueue,
              })}
            />
            {errors.keyTtsQueue && (
              <small className="p-error block">{errors.keyTtsQueue}</small>
            )}
          </div>
        </div>

        <div className="column">
          {tts && (
            <div className="field">
              <label htmlFor="tts">Voz de TTS</label>
              <Dropdown
                value={client.configuracionTTSVoiceName}
                filter
                onChange={(e) => handleTTs(e.value)}
                options={tts}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                placeholder="Elige el TTS"
                className={classNames({
                  "p-invalid": submitted && errors.configuracionTTSVoiceName,
                })}
              />
              {errors.configuracionTTSVoiceName && (
                <small className="p-error block">
                  {errors.configuracionTTSVoiceName}
                </small>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
