import React, { useEffect } from "react";
import {
  Button,
  Dropdown,
  InputSwitch,
  InputText,
  Password,
  Toolbar,
  classNames,
} from "primereact";
import { UserParentSelector } from "./UserParentSelector";
import UserChildren from "./UserChildren";
import { UserVendorAsterisks } from "./UserVendorAsterisks";

export const UserPanelContain = ({
  user,
  saveUser,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  clientes,
  retornoParentSelector,
  retornoVendorAsterisk,
  onClientChange,
  grupos,
  onGrupoChange,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [user]);

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!user.nombre.trim()) {
      newErrors.nombre = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.nombre = null;
    }

    if (!emailRegex.test(user.email)) {
      newErrors.email = "Ingresa un email válido";
      valid = false;
    } else {
      newErrors.email = null;
    }

    if (user.password === "" && user.id === "") {
      newErrors.password = "Ingresa la contraseña";
      valid = false;
    } else {
      newErrors.password = null;
    }

    if (!user.parent && user.id !== "1") {
      newErrors.parent = "Elige el padre";
      valid = false;
    } else {
      newErrors.parent = null;
    }

    if (!user.cliente) {
      newErrors.cliente = "Elige el cliente";
      valid = false;
    } else {
      newErrors.cliente = null;
    }

    if (!user.vendorAsterisk) {
      newErrors.vendorAsterisk = "Elige vendors / asterisk";
      valid = false;
    } else {
      newErrors.vendorAsterisk = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle usuario</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveUser();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="nombre">Nommbre</label>
            <InputText
              id="nombre"
              value={user.nombre}
              onChange={(e) => onInputChange(e, "nombre")}
              required
              autoFocus
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && errors.nombre,
              })}
              placeholder="Ingresa el nombre"
            />
            {errors.nombre && (
              <small className="p-error block">{errors.nombre}</small>
            )}
          </div>

          <div className="field switch">
            <InputSwitch
              checked={user.estado}
              onChange={(e) => onInputChange(e, "estado")}
            />
            <label htmlFor="estado">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            value={user.email}
            autoComplete="off"
            placeholder="Ingresa el email"
            onChange={(e) => onInputChange(e, "email")}
            required
            className={classNames({
              "p-invalid": submitted && errors.email,
            })}
          />
          {errors.email && (
            <small className="p-error block">{errors.email}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="password">Contraseña</label>
          <Password
            id="password"
            value={user.password}
            placeholder="Ingresa la contraseña"
            onChange={(e) => onInputChange(e, "password")}
            required
            toggleMask
            autoComplete="off"
            feedback={false}
            className={
              "password " +
              classNames({
                "p-invalid": submitted && errors.password,
              })
            }
          />
          {errors.pasword && (
            <small className="p-error block">{errors.pasword}</small>
          )}
        </div>

        <div className="sep"></div>

        <div className="field">
          <label htmlFor="parent">Padre</label>
          <UserParentSelector
            actualUserId={user.id}
            parent={user.parent}
            retornoParentSelector={retornoParentSelector}
            invalid={submitted && errors.parent ? true : false}
            rendKey={user.rendKey}
          />
          {errors.parent && (
            <small className="p-error block">{errors.parent}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="children">Hijos</label>
          <UserChildren children={user.children} />
        </div>

        <div className="sep"></div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="cliente">Cliente</label>
          <Dropdown
            value={clientes && clientes.find((c) => c.name === user.cliente)}
            onChange={(e) => onClientChange(e.value)}
            options={clientes}
            optionLabel="name"
            placeholder="Elige el cliente"
            className={classNames({
              "p-invalid": submitted && errors.cliente,
            })}
          />
          {errors.cliente && (
            <small className="p-error block">{errors.cliente}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="grupo">Grupo</label>
          <Dropdown
            value={grupos && grupos.find((g) => g.name === user.grupoName)}
            onChange={(e) => onGrupoChange(e.value)}
            options={grupos}
            optionLabel="name"
            placeholder="Elige el grupo"
            filter
          />
        </div>

        <div className="sep"></div>

        <div className="field">
          <UserVendorAsterisks
            user={user}
            retornoVendorAsterisk={retornoVendorAsterisk}
            rendKey={user.rendKey}
          />
          {errors.vendorAsterisk && (
            <small className="p-error block">{errors.vendorAsterisk}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
