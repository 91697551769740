import React, { useState } from "react";
import { styleEstado } from "../../utils/option.util";
import { Dropdown, InputText } from "primereact";
import { FilterWrapper } from "../_filterPanel/FilterWrapper";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  rubros,
  selectedRubroTemplate,
  itemRubroTemplate,
}) => {
  const initFilters = {
    nombre: "",
    rubro: "",
    estado: "",
  };

  const [filters, setFilters] = useState(initFilters);

  const optionsStatus = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Inactivo",
      value: false,
    },
  ];

  const itemStatusTemplate = (data) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(data.label === "Activo") +
          " text-status"
        }
      >
        {data.label}
      </span>
    );
  };

  const selectedStatusTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge status-" +
            styleEstado(option.label === "Activo") +
            " text-status"
          }
        >
          {option.label}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const filtros = [
    {
      title: "Nombre",
      filtro: "nombre",
      input: (
        <InputText
          className="filter-text"
          value={filters.nombre}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Rubro",
      filtro: "rubro",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.rubro}
            options={rubros}
            onChange={(e) => handleChange("rubro", e.value)}
            placeholder="Elige el rubro"
            optionLabel="name"
            valueTemplate={selectedRubroTemplate}
            itemTemplate={itemRubroTemplate}
          />
        </div>
      ),
    },
    {
      title: "Estado",
      filtro: "estado",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.estado}
            options={optionsStatus}
            onChange={(e) => handleChange("estado", e.value)}
            placeholder="Elige un estado"
            valueTemplate={selectedStatusTemplate}
            itemTemplate={itemStatusTemplate}
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
