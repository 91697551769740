import http from "../common/components/http-common";

class GroupService {
  ObtenerGrupos(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    const params = {
      name: data.name,
      direction: data.direction,
      field: data.field,
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params,
    };
    return http.get<any>("/v1/grupos/", config);
  }

  BorrarGroup(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.delete<any>("/v1/grupos/" + id, config);
  }

  CrearGroup(
    data: any,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<any>("/v1/grupos/", data, config);
  }

  UpdateGroup(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>("/v1/grupos/" + id, data, config);
  }
}

export default new GroupService();
