import http from "../common/components/http-common";

import { IPermisosType } from "../types/functionalities.type";

class PermisosDataService {
  obtenerPermisos(userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/functionalities/", config);
  }

  guardarPermisos(
    id,
    data: IPermisosType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IPermisosType>(
      "/v1/users/updateFunctions/" + id,
      data,
      config
    );
  }
}

export default new PermisosDataService();
