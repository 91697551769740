import { Button, Toolbar } from "primereact";
import { UserPermissions } from "./UserPermissions";

export const PermisosPanelContain = ({
  user,
  permisos,
  setPermisosUsuario,
  savePermissionsUser,
  permisosDisabled,
  closePanel,
}) => {
  const leftToolbarTemplate = () => {
    return (
      <>
        <div className="panel-title">Permisos usuario</div>
      </>
    );
  };

  const handleSave = () => {
    savePermissionsUser();
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon={permisosDisabled ? "pi pi-spin pi-spinner" : "pi pi-check"}
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
            disabled={permisosDisabled}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body" style={{ padding: "0" }}>
      <UserPermissions
        permisos={permisos}
        user={user}
        setPermisosUsuario={setPermisosUsuario}
        permisosDisabled={permisosDisabled}
      />
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
