import { Dropdown, classNames } from "primereact";
import React, { useEffect, useState } from "react";
import { viewTypes } from "../../common/types/views.type";
import userService from "../../services/user.service";

export const UserParentSelector = ({
  actualUserId,
  parent,
  retornoParentSelector,
  invalid,
  rendKey,
}) => {
  const { vnConfiguracion: viewName, vfAbmUsuarios: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  useEffect(() => {
    getParents();
  }, [actualUserId, rendKey]);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);

  const handleChange = (parent) => {
    setSelectedParent(parent);
    retornoParentSelector(parent);
  };

  const getParents = () => {
    setLoading(true);
    userService
      .GetParents(actualUserId || 0, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setUsers(parsedData.filter((user) => user.active === true));
          setSelectedParent(parsedData.find((c) => c.id === parent.id));
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  return (
    <>
      {!loading ? (
        <Dropdown
          value={selectedParent}
          onChange={(e) => handleChange(e.value)}
          options={users}
          filter
          optionLabel="name"
          placeholder="Seleciona el padre"
          className={
            "password " +
            classNames({
              "p-invalid": invalid,
            })
          }
        />
      ) : (
        <div>
          <i className="pi pi-spin pi-spinner" style={{ marginTop: "4px" }}></i>
        </div>
      )}
    </>
  );
};
