import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import clientService from "../../services/client.service";
import {
  IClientType,
  IClientTypeRequest,
  IClientTypeRequestCreated,
} from "../../types/client.type";
import { Paginator } from "primereact/paginator";
import rubroService from "../../services/rubro.service";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { ClientTable } from "./ClientTable";
import { Panel } from "../_slidePanel/Panel";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { EntidadesPanelContain } from "./ClientPanelContain";
import { styleRubro } from "../../utils/option.util";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  nombre: string;
  estado: string;
  rubro: string;
}

export default function ClientAbm({ resetView }) {
  const clientEmpty = {
    id: "",
    nombre: "",
    estado: false,
    rubro: "",
    langTranscript: "",
    telephoneValidation: "",
    keyTtsQueue: "",
    configuracionTTSservice: "",
    configuracionTTSVoiceName: "",
    keyReportQueue: "",
    notificationProperties: false,
    notificationPropertiesMilliseconds: "",
    configuracionGrabarLlamadas: false,
    configuracionGrabarTransferencias: false,
    configuracionSincronizarTTs: false,
    configuracionAMD: false,
    reintentos: "",
    credits: "",
    configurciontelephoneValidation: "",
  };

  const { vnConfiguracion: viewName, vfAbmClientes: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);

  const rubroEmpty = [{ id: "", name: "" }];

  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [activeClientDialog, setActiveClientDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [rubros, setRubros] = useState(rubroEmpty);
  const [customers, setCustomers] = useState(null);
  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    estado: "",
    rubro: "",
  };
  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  useEffect(() => {
    obtenerRubros();
  }, []);

  const obtenerRubros = () => {
    rubroService
      .obtenerRubros(userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setRubros(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";
    const idRubro = lazyParams.rubro ? lazyParams.rubro["id"] : null;

    let filter = {
      name: lazyParams.nombre,
      active: lazyParams.estado,
      rubroId: idRubro,
      pageSize: lazyParams.rows,
      pageNumber: lazyParams.page,
      direction: lazyParams.direction ? direction : null,
      field: lazyParams.sortField,
    };

    loadLazyTimeout = clientService
      .ObtenerClientes(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.clientes.map((c) => {
            let parsed: IClientType = {
              id: c.id,
              nombre: c.name,
              estado: c.active,
              rubro: c.rubroName,
              langTranscript: c.langTranscript,
              telephoneValidation: c.telephoneValidation,
              keyTtsQueue: c.keyTtsQueue,
              configuracionTTSservice: c.configuracionTTSservice,
              configuracionTTSVoiceName: c.configuracionTTSVoiceName,
              keyReportQueue: c.keyReportQueue,
              notificationProperties: c.notificationProperties,
              notificationPropertiesMilliseconds:
                c.notificationPropertiesMilliseconds,
              configuracionGrabarLlamadas: c.configuracionGrabarLlamadas,
              configuracionGrabarTransferencias:
                c.configuracionGrabarTransferencias,
              configuracionSincronizarTTs: c.configuracionSincronizarTTs,
              configuracionAMD: c.configuracionAMD,
              reintentos: c.reintentos,
              credits: c.credits,
              configurciontelephoneValidation:
                c.configurciontelephoneValidation,
            };
            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    setClient(clientEmpty);
    setSubmitted(false);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const hideActiveClientDialog = () => {
    setActiveClientDialog(false);
  };

  const saveClient = () => {
    if (client.nombre.trim()) {
      const idRubro = rubros && rubros.find((r) => r.name === client.rubro).id;

      const clienteObj: IClientTypeRequestCreated = {
        name: client.nombre,
        active: client.estado,
        rubroId: idRubro,
        langTranscript: client.langTranscript,
        telephoneValidation: client.telephoneValidation,
        keyTtsQueue: client.keyTtsQueue,
        configuracionTTSservice: client.configuracionTTSservice,
        configuracionTTSVoiceName: client.configuracionTTSVoiceName,
        keyReportQueue: client.keyReportQueue,
        notificationProperties: client.notificationProperties,
        notificationPropertiesMilliseconds:
          client.notificationPropertiesMilliseconds,
        configuracionGrabarLlamadas: client.configuracionGrabarLlamadas,
        configuracionGrabarTransferencias:
          client.configuracionGrabarTransferencias,
        configuracionSincronizarTTs: client.configuracionSincronizarTTs,
        configuracionAMD: client.configuracionAMD,
        reintentos: client.reintentos,
        credits: client.credits,
        configurciontelephoneValidation: client.configurciontelephoneValidation,
      };

      if (client.id != "") {
        clientService
          .ModificarCliente(
            client.id,
            clienteObj,
            userName,
            viewName,
            viewFunction
          )
          .then((response: any) => {
            try {
              setClient(clienteObj);
              closePanel();
              toast.current.show({
                severity: "success",
                summary: "Cambio realizado correctamente",
                detail: "Cliente modificado",
                life: 3000,
              });
              setClient(clientEmpty);
              loadLazyData();
            } catch (err) {
              const error = err as { message?: string };
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Error en la carga",
              detail: e.response.data.message,
              life: 3000,
            });
          });
      } else {
        clientService
          .CrearCliente(clienteObj, userName, viewName, viewFunction)
          .then((response: any) => {
            try {
              setClient(clienteObj);
              closePanel();
              toast.current.show({
                severity: "success",
                summary: "Alta realizada correctamente",
                detail: "Cliente guardado",
                life: 3000,
              });
              setClient(clientEmpty);
              loadLazyData();
            } catch (err) {
              const error = err as { message?: string };
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Error en la carga",
              detail: e.response.data.message,
              life: 3000,
            });
          });
      }
      resetErrors();
      setSubmitted(false);
      closePanel();
      setClient(clientEmpty);
    }
  };

  const editClient = (client) => {
    setClient({ ...client });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const switchClient = () => {
    const strState = !client.estado ? "activado" : "desactivado";

    const id = client.id;
    const updatePartial: IClientTypeRequest = {
      id: null,
      name: null,
      rubroId: null,
      langTranscript: null,
      telephoneValidation: null,
      keyTtsQueue: null,
      configuracionTTSservice: null,
      configuracionTTSVoiceName: null,
      keyReportQueue: null,
      notificationProperties: null,
      notificationPropertiesMilliseconds: null,
      configuracionGrabarLlamadas: null,
      configuracionGrabarTransferencias: null,
      configuracionSincronizarTTs: null,
      configuracionAMD: null,
      reintentos: null,
      credits: null,
      configurciontelephoneValidation: null,
      active: !client.estado,
    };
    clientService
      .updatePartial(id, updatePartial, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setActiveClientDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: `Cliente ${client.nombre} ${strState}`,
            life: 3000,
          });
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el cambio",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const confirmSwitchClient = (client) => {
    setClient(client);
    setActiveClientDialog(true);
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;
    let _client = { ...client };
    _client[`${name}`] = val;
    setClient(_client);
  };

  const onClientChange = (cliente) => {
    setClient({ ...client, rubro: cliente.name });
  };

  const onTTSChange = (ttsService, ttsVoice) => {
    setClient({
      ...client,
      configuracionTTSservice: ttsService,
      configuracionTTSVoiceName: ttsVoice,
    });
  };

  const onIdiomaChange = (idioma) => {
    setClient({ ...client, langTranscript: idioma });
  };

  const onValidationChange = (validation) => {
    setClient({ ...client, telephoneValidation: validation });
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const itemRubroTemplate = (data) => {
    return (
      <span
        className={
          "customer-badge status-" + styleRubro(data.name) + " text-status"
        }
      >
        {data.name}
      </span>
    );
  };

  const selectedRubroTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge status-" + styleRubro(option.name) + " text-status"
          }
        >
          {option.name}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    nombre: null,
    notificationPropertiesMilliseconds: null,
    credits: null,
    keyReportQueue: null,
    reintentos: null,
    rubro: null,
    telephoneValidation: null,
    langTranscript: null,
    keyTtsQueue: null,
    configuracionTTSVoiceName: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <ClientTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            initParams={initParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            editClient={editClient}
            rubros={rubros}
            confirmSwitchClient={confirmSwitchClient}
            resetView={resetView}
            selectedRubroTemplate={selectedRubroTemplate}
            itemRubroTemplate={itemRubroTemplate}
          />
          {client && (
            <ConfirmationDialog
              client={client}
              activeClientDialog={activeClientDialog}
              hideActiveClientDialog={hideActiveClientDialog}
              switchClient={switchClient}
            />
          )}
          {client && rubros && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <EntidadesPanelContain
                client={client}
                saveClient={saveClient}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                rubros={rubros}
                selectedRubroTemplate={selectedRubroTemplate}
                itemRubroTemplate={itemRubroTemplate}
                onClientChange={onClientChange}
                onTTSChange={onTTSChange}
                onIdiomaChange={onIdiomaChange}
                onValidationChange={onValidationChange}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
