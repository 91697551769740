import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import clientService from "../../services/client.service";
import userService from "../../services/user.service";
import { Paginator } from "primereact/paginator";
import {
  IUserTypeRequest,
  IUserTypeRequestCreated,
} from "../../types/user.type";
import permissionsService from "../../services/permissions.service";
import { IPermisosType } from "../../types/functionalities.type";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { ObtenerPermisosUser } from "../../common/components/ObtenerPermisosUser";
import groupService from "../../services/group.service";
import { UserTable } from "./UserTable";
import { Panel } from "../_slidePanel/Panel";
import { PermisosPanelContain } from "./PermisosPanelContain";
import { UserPanelContain } from "./UserPanelContain";
import { ConfirmationDialog } from "./ConfirmationDialog";
import asteriskService from "../../services/asterisk.service";
import { IAsteriskType } from "../../types/asterisk.type";
import { IVendorFilterType, IVendorType } from "../../types/vendor.type";
import vendorService from "../../services/vendor.service";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  nombre: string;
  email: string;
  password: string;
  estado: string;
  cliente: string;
  permisos: string;
  grupo: any;
  vendors: string;
  asterisks: string;
}

export default function UserAbm({ resetView }) {
  const userEmpty = {
    id: "",
    nombre: "",
    email: "",
    password: "",
    estado: false,
    cliente: "",
    permisos: "",
    grupo: "",
    grupoName: "",
    parent: "",
    children: "",
    vendorAsterisk: "",
    rendKey: null,
  };

  const {
    vnConfiguracion: viewName,
    vfAbmUsuarios: viewFunction,
    vfVerPermisos,
  } = viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);
  const showPermisos = ShowView(vfVerPermisos, viewName);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(userEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [activeUserDialog, setActiveUserDialog] = useState(false);
  const [permisosUsuario, setPermisosUsuario] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [clientes, setClientes] = useState(null);
  const [grupos, setGrupos] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [selectGroup, setSelectGroup] = useState<any>(null);
  const [parentId, setParentId] = useState(null);

  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    email: "",
    password: "",
    estado: "",
    cliente: "",
    permisos: "",
    grupo: "",
    vendors: "",
    asterisks: "",
  };
  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  useEffect(() => {
    obtenerClientes();
    getGrupos();
    obtenerAsteriks();
    obtenerVendors();
  }, []);

  useEffect(() => {
    obtenerPermisos(userName, viewName, viewFunction);
  }, []);

  const [optionsAsterisks, setOptionsAsterisks] = useState(null);
  const [optionsVendors, setOptionsVendors] = useState(null);

  const obtenerVendors = () => {
    let filterVend = {
      name: "",
      queueName: "",
      context: "",
      asterisks: "",
      direction: null,
      sortField: null,
      pageSize: 100,
      pageNumber: 1,
    };

    loadLazyTimeout = vendorService
      .ObtenerProveedores(filterVend, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.vendors.map((c) => {
            let parsed: IVendorType = {
              id: c.id,
              name: c.name,
              channel: c.channel,
              cps: c.cps,
              queueName: c.queueName,
              context: c.context,
              asterisks: c.asterisks,
            };

            return parsed;
          });
          setOptionsVendors(
            parsedData.map((a) => ({
              name: a.name,
              value: a.id,
            }))
          );
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const obtenerAsteriks = () => {
    let filterAst = {
      rows: 100,
      page: 1,
      sortField: null,
      direction: null,
      serverName: "",
      active: "",
      ip: "",
    };

    asteriskService
      .ObtenerAsterisks(filterAst, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.asterisks.map((c) => {
            let parsed: IAsteriskType = {
              id: c.id,
              serverName: c.serverName,
              ip: c.ip,
              port: c.port,
              user: c.user,
              password: c.password,
              context: c.context,
              exten: c.exten,
              orden: c.orden,
              prefix: c.prefix,
              pathTTs: c.pathTTs,
              active: c.active,
            };
            return parsed;
          });

          setOptionsAsterisks(
            parsedData.map((a) => ({
              name: a.serverName,
              value: a.id,
            }))
          );
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const obtenerClientes = () => {
    clientService
      .ListDTO(userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setClientes(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const getGrupos = () => {
    groupService
      .ObtenerGrupos({ pageSize: 10000 }, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.grupos;
          setGrupos(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const obtenerPermisos = (userName, viewName, viewFunction) => {
    permissionsService
      .obtenerPermisos(userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          setPermisos(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    const idClient = lazyParams.cliente ? lazyParams.cliente["id"] : null;
    const idGrupo = lazyParams.grupo ? lazyParams.grupo["id"] : null;
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      name: lazyParams.nombre,
      email: lazyParams.email,
      active: lazyParams.estado,
      clientId: idClient,
      pageSize: lazyParams.rows,
      pageNumber: lazyParams.page,
      direction: lazyParams.direction ? direction : null,
      field: lazyParams.sortField,
      grupoId: idGrupo,
      vendors: lazyParams.vendors,
      asterisks: lazyParams.asterisks,
    };

    function reduceObjectVA(inputArray) {
      let resultArray = [];

      inputArray.forEach((item) => {
        let vendorId = item.vendor.id;
        let asteriskId = item.asterisk.id;
        let esDefault = item.esDefault;

        resultArray.push({
          vendor: vendorId,
          asterisk: asteriskId,
          esDefault: esDefault,
        });
      });

      return resultArray;
    }

    loadLazyTimeout = userService
      .ObtenerUsuarios(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.users.map((c) => {
            let parsed: any = {
              id: c.id,
              nombre: c.name,
              email: c.email,
              password: "",
              estado: c.active,
              cliente: c.client.name,
              permisos: c.funcionalidades,
              grupoName: c.grupoName,
              parent: c.parent,
              children: c.childrens,
              vendorAsterisk: reduceObjectVA(c.vendorAsterisk),
            };
            return parsed;
          });

          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    userEmpty.rendKey = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
    setUser(userEmpty);
    resetErrors();
    setSubmitted(false);
    setIsOpen(true);
  };

  const hideActiveUserDialog = () => {
    setActiveUserDialog(false);
  };

  const [permisosDisabled, setPermisosDisabled] = useState(false);

  const savePermissionsUser = () => {
    const parsed: IPermisosType = {
      userId: user.id,
      functionalities: permisosUsuario,
    };

    setPermisosDisabled(true);

    permissionsService
      .guardarPermisos(user.id, parsed, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setPermisosDisabled(false);
          closePermisosPanel();
          ObtenerPermisosUser();
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: "Permisos guardados",
            life: 3000,
          });
          setUser(userEmpty);
          loadLazyData();
        } catch (err) {
          setPermisosDisabled(false);
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        setPermisosDisabled(false);
        toast.current.show({
          severity: "error",
          summary: "Error en la carga",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const saveUser = () => {
    const clientId =
      clientes && clientes.find((c) => c.name === user.cliente).id;
    const groupId = grupos && grupos.find((g) => g.name === user.grupoName).id;

    const prevenirDefault = (array) => {
      return array.map((obj) => ({
        ...obj,
        esDefault: obj.hasOwnProperty("esDefault") ? obj.esDefault : false,
      }));
    };

    const userObj: IUserTypeRequestCreated = {
      name: user.nombre,
      email: user.email,
      password: user.password === "" ? null : user.password,
      active: user.estado,
      clientId: clientId,
      grupoId: groupId,
      parentId: parentId,
      vendorAsterisks: prevenirDefault(user.vendorAsterisk),
    };

    if (user.id != "") {
      userService
        .updatePartial(user.id, userObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Usuario modificado",
              life: 3000,
            });
            setUser(userEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      userService
        .CrearUsuario(userObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Usuario guardado",
              life: 3000,
            });
            setUser(userEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setUser(userEmpty);
  };

  const editUser = (user) => {
    setUser({ ...user });
    resetErrors();
    setSubmitted(false);
    setIsOpen(true);
  };

  const editPermissions = (user) => {
    setUser({ ...user });
    setIsPermisosOpen(true);
  };

  const confirmSwitchUser = (user) => {
    setUser({ ...user });
    setActiveUserDialog(true);
  };

  const switchUser = () => {
    const strState = !user.estado ? "activado" : "desactivado";
    const id = user.id;
    const updatePartial: IUserTypeRequest = {
      id: null,
      name: null,
      email: null,
      password: null,
      clientId: null,
      grupoId: null,
      parentId: null,
      vendorAsterisks: null,
      vendors: null,
      asterisks: null,
      active: !user.estado,
    };
    userService
      .updatePartial(id, updatePartial, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          setActiveUserDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Cambio realizado correctamente",
            detail: `Usuario ${user.nombre} ${strState}`,
            life: 3000,
          });
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el cambio",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;
    let _user = { ...user };
    _user[`${name}`] = val;
    setUser(_user);
  };

  const onGrupoChange = (grupo) => {
    setUser({ ...user, grupoName: grupo.name });
  };

  const onClientChange = (client) => {
    setUser({ ...user, cliente: client.name });
  };

  const retornoParentSelector = (parent) => {
    setUser({ ...user, parent: parent.name });
    setParentId(parent.id);
  };

  const retornoVendorAsterisk = (va, defaultVa) => {
    const _va = va ? va : user.vendorAsterisk;
    const [defaultVendor, defaultAsterisk] = defaultVa
      ? defaultVa.split("-")
      : [null, null];

    const updatedVa = _va.map((obj) => ({
      ...obj,
      esDefault:
        defaultVa !== null &&
        obj.vendor === defaultVendor &&
        obj.asterisk === defaultAsterisk,
    }));

    setUser({ ...user, vendorAsterisk: updatedVa });
  };

  const [currentPage, setCurrentPage] = useState(lazyParams.page);

  const pageChange = (event) => {
    setCurrentPage(event.page + 1);

    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const [isPermisosOpen, setIsPermisosOpen] = useState(false);
  const closePermisosPanel = () => {
    setIsPermisosOpen(false);
  };

  const initErrors = {
    nombre: null,
    email: null,
    password: null,
    parent: null,
    cliente: null,
  };
  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />
          <UserTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            editUser={editUser}
            editPermissions={editPermissions}
            confirmSwitchUser={confirmSwitchUser}
            resetView={resetView}
            initParams={initParams}
            clientes={clientes}
            grupos={grupos}
            optionsAsterisks={optionsAsterisks}
            optionsVendors={optionsVendors}
            showPermisos={showPermisos}
          />

          {user && permisos && (
            <Panel isOpen={isPermisosOpen} closePanel={closePermisosPanel}>
              <PermisosPanelContain
                user={user}
                permisos={permisos}
                setPermisosUsuario={setPermisosUsuario}
                permisosDisabled={permisosDisabled}
                savePermissionsUser={savePermissionsUser}
                closePanel={closePermisosPanel}
              />
            </Panel>
          )}

          {user && clientes && grupos && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <UserPanelContain
                user={user}
                saveUser={saveUser}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                clientes={clientes}
                retornoParentSelector={retornoParentSelector}
                retornoVendorAsterisk={retornoVendorAsterisk}
                onClientChange={onClientChange}
                grupos={grupos}
                onGrupoChange={onGrupoChange}
              />
            </Panel>
          )}

          {user && clientes && grupos && (
            <ConfirmationDialog
              user={user}
              activeUserDialog={activeUserDialog}
              hideActiveUserDialog={hideActiveUserDialog}
              switchUser={switchUser}
            />
          )}
        </div>
      )}
    </>
  );
}
