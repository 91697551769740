const styleEstado = (estado) => {
  let stile = "unqualified";
  if (estado) stile = "qualified";
  return stile;
};

const styleRubro = (rubro) => {
  switch (rubro) {
    case "BANCARIO":
      return "new";

    case "SOPORTE":
      return "qualified";

    case "TELEMARKETING":
      return "renewal";

    case "TODOS":
      return "";
    default:
      return "proposal";
  }
};

export { styleEstado, styleRubro };
