import React, { useState } from "react";
import { InputText, MultiSelect } from "primereact";
import { FilterWrapper } from "../_filterPanel/FilterWrapper";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  optionsUsers,
}) => {
  const initFilters = {
    name: "",
    description: "",
    userIds: "",
  };

  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Nombre",
      filtro: "name",
      input: (
        <InputText
          className="filter-text"
          value={filters.name}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Descripción",
      filtro: "description",
      input: (
        <InputText
          className="filter-text"
          value={filters.description}
          placeholder="Ingresa la descripción"
        />
      ),
    },
    {
      title: "Usuarios",
      filtro: "userIds",
      input: (
        <div className="select-container">
          <MultiSelect
            value={filters.userIds}
            options={optionsUsers}
            onChange={(e) => handleChange("userIds", e.value)}
            optionLabel="name"
            placeholder="Selecciona los usuarios"
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
