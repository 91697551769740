import http from "../common/components/http-common";
import {
  IClientTypeRequest,
  IClientTypeRequestCreated,
} from "../types/client.type";

class ClientService {
  ObtenerClientes(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        name: data.name,
        active: data.active,
        rubroId: data.rubroId,
        direction: data.direction,
        field: data.field,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
      },
    };
    return http.get<any>("/v1/clients/", config);
  }

  ListDTO(userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/clients/list/", config);
  }

  CrearCliente(
    data: IClientTypeRequestCreated,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IClientTypeRequest>("/v1/clients/", data, config);
  }

  ModificarCliente(
    id,
    data: IClientTypeRequestCreated,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<IClientTypeRequest>("/v1/clients/" + id, data, config);
  }

  updatePartial(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<any>("/v1/clients/" + id, data, config);
  }

  GetConfig() {
    return http.get<any>("/v1/clients/configurationClient/");
  }
}

export default new ClientService();
