import http from "../common/components/http-common";
var qs = require("qs");

class AnalyticService {
  ObtenerAnalytics(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let params = {
      field: data.field,
      pageSize: data.size,
      pageNumber: data.page,
      direction: data.direction,
      name: data.name,
      description: data.description,
      userIds: data.userIds,
    };

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "brackets" });
      },
    };
    return http.get<any>("/v1/pbi-report/", config);
  }

  borrarReporte(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.delete<any>("/v1/pbi-report/" + id, config);
  }

  CrearAnalytic(
    data: any,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<any>("/v1/pbi-report/", data, config);
  }

  UpdateAnalytic(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>("/v1/pbi-report/" + id, data, config);
  }

  UpdateAnalyticUsers(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let ids = data.userIds;

    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<any>("/v1/pbi-report/" + id + "/users", ids, config);
  }
}

export default new AnalyticService();
