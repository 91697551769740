import React from "react";

const UserChildren = ({ children }) => {
  return (
    <div
      style={{
        background: "#ffffff",
        color: " #495057",
        border: "1px solid #dee2e6",
        borderRadius: "3px",
        padding: "10px",
        width: "100%",
        height: "210px",
        overflowY: "auto",
      }}
    >
      {children.length > 0 ? (
        children.map((c) => <div key={c.id}>{c.name}</div>)
      ) : (
        <span>Sin hijos asignados</span>
      )}
    </div>
  );
};

export default UserChildren;
