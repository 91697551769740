import React, { useEffect } from "react";
import { Button, InputText, Toolbar, classNames } from "primereact";

export const GroupPanelContain = ({
  group,
  saveGroup,
  onDataChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [group]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!group.name.trim()) {
      newErrors.name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.name = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle grupos de usuarios</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveGroup();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="name"
            value={group.name}
            onChange={(e) => onDataChange("name", e.target.value)}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.name,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.name && (
            <small className="p-error block">{errors.name}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
