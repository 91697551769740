import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  user,
  activeUserDialog,
  hideActiveUserDialog,
  switchUser,
}) => {
  const activeUserDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={switchUser}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveUserDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={activeUserDialog}
      header="Cambiar estado usuario"
      modal
      footer={activeUserDialogFooter}
      onHide={hideActiveUserDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>
          ¿Estas seguro que quieres {user.estado ? "desactivar" : "activar"}{" "}
          <b>{user.nombre}</b>?
        </span>
      </div>
    </Dialog>
  );
};
